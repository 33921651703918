import MainWrapper from "@components/pages/common/MainWrapper";
import baseApiCalls from "@services/baseApiCalls";
import { GetStaticProps } from "next";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import MediaAssets from "../../components/utils/MediaAssets";

const pageNotFound = (): JSX.Element => {
  const router = useRouter();

  return (
    <MainWrapper>
      <div className="relative block h-fit min-h-screen desktop:hidden">
        <div className="relative flex h-[250px] justify-center bg-[#e5e5e5] pt-[23px]">
          <Image
            src={MediaAssets.getAsset("frog_404_page")}
            height="204"
            width="350"
            layout="fixed"
            alt="404 frog image"
          />
        </div>
        <div className="mb-14 ml-12 mt-12 w-fit">
          <p className="font-semibold text-darkgray">
            Ooops... page not found.
          </p>
          <Image
            src={MediaAssets.getAsset("404")}
            width="193"
            height="96"
            layout="fixed"
            alt="404 page not found"
          />
        </div>
        <div className="mx-auto flex h-[85px] w-[100%] max-w-[390px] items-center justify-center rounded-full border-[24px] border-white">
          <button
            onClick={() => router.back()}
            className="bottom-6 left-4 mx-auto flex w-[100%] max-w-[350px] justify-center rounded-full bg-orange-100 p-5 text-white hover:bg-orange"
          >
            Go Back
          </button>
        </div>
        <div className="absolute bottom-0 right-0 -z-10">
          <Image
            src={MediaAssets.getAsset("whitecard_waves")}
            width="776"
            height="358"
            layout="fixed"
            alt="whitecard waves background"
          />
        </div>
      </div>
      <div className="hidden h-full items-center justify-center desktop:block desktop:flex">
        <div className="relative my-[200px] h-[400px] w-[1300px] overflow-hidden rounded-2xl bg-blue-5">
          <div className="flex h-[400px] w-[1300px] items-center">
            <div>
              <Image
                className="w-[400px]"
                src={MediaAssets.getAsset("frog_404_page")}
                height="273"
                width="468"
                layout="fixed"
                alt="frog 404 page"
              />
            </div>
            <div className="ml-12 flex flex-col">
              <p className="font-semibold text-darkgray">
                Ooops... page not found.
              </p>
              <Image
                src={MediaAssets.getAsset("404")}
                width="193"
                height="96"
                layout="fixed"
                alt="404 page not found"
              />
              <button
                onClick={() => router.back()}
                className="bottom-6 left-4 mt-3 flex h-[58px]  w-[118px] items-center justify-center rounded-full bg-orange-100 px-[24px] text-white hover:bg-orange"
              >
                Go Back
              </button>
            </div>
            <div className="relative h-[400px] w-[600px]">
              <Image
                src={MediaAssets.getAsset("whitecard_waves")}
                width="600"
                height="400"
                layout="fill"
                objectFit="cover"
                alt="whitecard waves background"
              />
            </div>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default pageNotFound;

export const getStaticProps: GetStaticProps = async () => {
  const {
    footer,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp,
  } = await baseApiCalls();

  return {
    props: {
      footer,
      mediaAssets,
      topNavigation,
      menus,
      usp,
      searchManagement: searchManagementRes,
    },
    revalidate: Number(process.env.REVALIDATE_TIMER),
  };
};
